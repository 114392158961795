/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import YouTube from 'react-youtube';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { useScreenRecognition } from 'hooks/useScreenRecognition';

import CustomButton from 'common/CustomButton';
import CustomLink from 'common/CustomLink';
import Image from 'common/Image';
import { BANNER_TYPE, YOUTUBE_OPTS } from 'utils/constants';

import './Banner.scss';

const Banner = ({
  type,
  image,
  topText,
  mainTitle: { title, isHeadingLarge, isMainHeading },
  description,
  customButton,
  customLink,
  heroBanner,
  videoBanner,
  className,
  buttonArialabel,
}: PageContent.BannerType) => {
  const { videoId, videoThumbnail } = videoBanner || {};
  const {
    mobileImage,
    desktopImage,
    backgroundColor = false,
    mobileAlign = false,
  } = heroBanner || {};
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const isVideo = type === BANNER_TYPE.video;
  const isHero = type === BANNER_TYPE.hero;
  const { isMiddleMobile } = useScreenRecognition();

  const imageData = isVideo
    ? videoThumbnail
    : (image ||= isHero ? (isMiddleMobile ? mobileImage : desktopImage) : image);
  const imageAlt = isVideo
    ? videoThumbnail.altText
    : (image.altText ||= isHero
        ? isMiddleMobile
          ? mobileImage.altText
          : desktopImage.altText
        : image.altText);

  return (
    <div
      className={classNames(className, 'banner', {
        [`banner--${type}`]: type,
        [`banner--background`]: backgroundColor,
      })}
      data-testid="banner"
    >
      <div
        className={classNames('banner__content', {
          [`banner__content--${type}`]: type,
          [`banner__content--large-heading`]: isHeadingLarge,
        })}
        data-testid="banner-content"
      >
        {topText ? <p className="banner__top-text eyebrow">{topText}</p> : null}
        {isMainHeading ? <h1>{title}</h1> : <h2>{title}</h2>}
        <p className="banner__description">{description}</p>
        {customButton ? (
          <CustomButton
            buttonLabel={customButton.buttonLabel}
            ariaLabel={customButton.ariaLabel}
            backgroundColor={customButton.backgroundColor}
            buttonLink={customButton.buttonLink}
          />
        ) : null}
        {customLink ? (
          <CustomLink
            linkLabel={customLink.linkLabel}
            color={customLink.color}
            link={customLink.link}
            isLinkExternal={customLink.isLinkExternal}
          />
        ) : null}
      </div>
      <div
        className={classNames('banner__image', {
          [`banner__image--${type}`]: type,
          [`banner__image--mobile-align`]: mobileAlign,
        })}
      >
        {isVideoVisible ? (
          <YouTube opts={YOUTUBE_OPTS} {...{ videoId }} className="banner__youtube" />
        ) : (
          <button
            onClick={() => isVideo && setIsVideoVisible(true)}
            onKeyDown={() => isVideo && setIsVideoVisible(true)}
            type="button"
            className="banner__thumbnail"
            aria-label={buttonArialabel}
          >
            <Image imageData={imageData} alt={imageAlt} />
          </button>
        )}
      </div>
    </div>
  );
};

export const BannerType = graphql`
  fragment BannerType on BannerType {
    type
    topText
    description
    mainTitle {
      title
      isMainHeading
      isHeadingLarge
    }
    customButton {
      ...CustomButtonType
    }
    customLink {
      ...CustomLinkType
    }
    image {
      ...UmbracoImage
    }
    heroBanner {
      mobileImage {
        ...UmbracoImage
      }
      desktopImage {
        ...UmbracoImage
      }
      backgroundColor
      mobileAlign
    }
    videoBanner {
      videoId
      videoThumbnail {
        ...UmbracoImage
      }
    }
    buttonArialabel
  }
`;

export default Banner;
